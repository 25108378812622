import React, { useEffect } from 'react';
import { Flex, Heading, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Page from '../components/Page';
import { isDashboardPage, isRedirectAttempt } from '../libs/utils';

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    const { origin, attempt } = router.query;
    const path = router.asPath;

    // Do not allow more than 1 attempt
    if (isRedirectAttempt(path)) {
      return;
    }

    // Redirect to path with trailing slash if it doesn't have one
    if (!path.endsWith('/')) {
      router.replace(`${path}/?attempt=1`, undefined, { shallow: true }); // TODO: Test if shallow works for S3 redirects
      return;
    }

    // Redirect for dynamic dashboard pages
    if (isDashboardPage(path)) {
      router.replace(`${path}/?attempt=1`, undefined, { shallow: true }); // Redirect to the same path with a trailing slash
      return;
    }

    // Prevent infinite loops from s3 by only allowing one attempt.
    if (!origin || attempt) {
      return;
    }

    router.replace(`${origin}?attempt=1`);
  }, [router]);

  return (
    <Page
      maxW='100%'
      p={0}
      m={0}
      seoOptions={{
        title: '404 - Page not found',
        description: 'The page you are looking for cannot be found.',
        noindex: true,
        nofollow: true
      }}
    >
      <Flex justifyContent='center' alignItems='center' h='100vh'>
        <Flex alignItems='center'>
          <VStack spacing={4}>
            <Heading
              as='h1'
              size='xl'
              fontWeight='extrabold'
              letterSpacing='tight'
              mx='auto'
              textAlign='center'
              color='accent'
            >
              Oops&hellip; a 404
            </Heading>

            <Heading as='h2' size='sm' fontWeight='extrabold' letterSpacing='tight' mx='auto' textAlign='center'>
              Looks like we cannot find the page you are looking for.
            </Heading>
          </VStack>
        </Flex>
      </Flex>
    </Page>
  );
}
